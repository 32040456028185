import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

// Style
import style from './Header.module.scss'

// Hooks
import { useInView } from 'react-intersection-observer'

// Components
import { Link } from 'gatsby'
import { Container, Row, Col } from 'reactstrap'
import Navbar from '../Navbar'
import Brand from '../Brand'

// Assets
import scientific from '@assets/images/scientific.png'

const Header = ({ title, description, path }) => {
  const [isPinned, setPinned] = useState()
  const prevIsPinned = useRef()

  const [ref, inView] = useInView({ threshold: 0 })

  useEffect(() => {
    if (typeof prevIsPinned.current === 'boolean') {
      setPinned(!inView)
    }
    prevIsPinned.current = !inView
  }, [inView])

  const classes = cn(style.root, {
    [style.isPinned]: isPinned,
  })

  return (
    <header className={classes}>
      <div className={style.header} ref={ref}>
        <Navbar />
        <Container className={style.container}>
          <Brand tag={path === '/' ? 'h1' : 'span'} withHomeHint={path !== '/'}>
            PAROLE AL LAVORO
          </Brand>
          <Row>
            <Col md={{ size: 10, offset: 1 }} lg={{ size: 8, offset: 2 }}>
              <div
                className={style.intro}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <div className={style.smallBar}>
        <Brand className={style.smallBrand}>PAROLE AL LAVORO</Brand>
      </div>
    </header>
  )
}

export default Header
