// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-thanks-js": () => import("./../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-typography-js": () => import("./../src/pages/typography.js" /* webpackChunkName: "component---src-pages-typography-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../src/templates/BlogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */),
  "component---src-templates-homepage-index-js": () => import("./../src/templates/Homepage/index.js" /* webpackChunkName: "component---src-templates-homepage-index-js" */),
  "component---src-templates-parola-index-js": () => import("./../src/templates/Parola/index.js" /* webpackChunkName: "component---src-templates-parola-index-js" */),
  "component---src-templates-parole-index-js": () => import("./../src/templates/Parole/index.js" /* webpackChunkName: "component---src-templates-parole-index-js" */),
  "component---src-templates-tema-index-js": () => import("./../src/templates/Tema/index.js" /* webpackChunkName: "component---src-templates-tema-index-js" */)
}

