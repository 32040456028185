import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

// Style
import style from './Brand.module.scss'

// Components
import { Link } from 'gatsby'

const Brand = ({ children, className, withHomeHint, tag: Tag = 'span' }) => {
  const classes = cn(style.root, className, {
    [style.withHint]: withHomeHint,
  })

  return withHomeHint ? (
    <Tag>
      <Link className={classes} to="/" title="Home">
        {children}
      </Link>
    </Tag>
  ) : (
    <Tag className={classes}>
      <Link to="/" title="Home">
        {children}
      </Link>
    </Tag>
  )
}

Brand.propTypes = {
  withHomeHint: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default Brand
