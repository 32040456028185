import React from 'react'
import cn from 'classnames'

// Components
import { Link } from 'gatsby'

// Style
import style from './Navbar.module.scss'

const Navbar = class extends React.Component {
  render() {
    return (
      <nav
        className={style.root}
        role="navigation"
        aria-label="main-navigation"
      >
        <ul className={style.list}>
          {/* <li className={style.item}>
            <Link to="/">Articoli</Link>
          </li> */}
          <li className={style.item}>
            <Link to="/parole">Temi e parole</Link>
          </li>
          <li className={cn(style.item, style.icon)}>
            <a
              href="https://www.linkedin.com/in/girardianna"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                enableBackground="new 0 0 24 24"
                viewBox="0 0 24 24"
                width="14"
                height="14"
              >
                <path
                  fill="#f1610b"
                  d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"
                />
                <path fill="#f1610b" d="m.396 7.977h4.976v16.023h-4.976z" />
                <path
                  fill="#f1610b"
                  d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"
                />
              </svg>
            </a>
          </li>
          <li className={cn(style.item, style.icon)}>
            <a
              href="https://twitter.com/_annagirardi"
              target="_blank"
              rel="noopener noreferrer"
            >
              <svg
                viewBox="0 -47 512 512"
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
              >
                <path
                  fill="#f1610b"
                  d="m191.011719 419.042969c-22.140625 0-44.929688-1.792969-67.855469-5.386719-40.378906-6.335938-81.253906-27.457031-92.820312-33.78125l-30.335938-16.585938 32.84375-10.800781c35.902344-11.804687 57.742188-19.128906 84.777344-30.597656-27.070313-13.109375-47.933594-36.691406-57.976563-67.175781l-7.640625-23.195313 6.265625.957031c-5.941406-5.988281-10.632812-12.066406-14.269531-17.59375-12.933594-19.644531-19.78125-43.648437-18.324219-64.21875l1.4375-20.246093 12.121094 4.695312c-5.113281-9.65625-8.808594-19.96875-10.980469-30.777343-5.292968-26.359376-.863281-54.363282 12.476563-78.851563l10.558593-19.382813 14.121094 16.960938c44.660156 53.648438 101.226563 85.472656 168.363282 94.789062-2.742188-18.902343-.6875-37.144531 6.113281-53.496093 7.917969-19.039063 22.003906-35.183594 40.722656-46.691407 20.789063-12.777343 46-18.96875 70.988281-17.433593 26.511719 1.628906 50.582032 11.5625 69.699219 28.746093 9.335937-2.425781 16.214844-5.015624 25.511719-8.515624 5.59375-2.105469 11.9375-4.496094 19.875-7.230469l29.25-10.078125-19.074219 54.476562c1.257813-.105468 2.554687-.195312 3.910156-.253906l31.234375-1.414062-18.460937 25.230468c-1.058594 1.445313-1.328125 1.855469-1.703125 2.421875-1.488282 2.242188-3.339844 5.03125-28.679688 38.867188-6.34375 8.472656-9.511718 19.507812-8.921875 31.078125 2.246094 43.96875-3.148437 83.75-16.042969 118.234375-12.195312 32.625-31.09375 60.617187-56.164062 83.199219-31.023438 27.9375-70.582031 47.066406-117.582031 56.847656-23.054688 4.796875-47.8125 7.203125-73.4375 7.203125zm0 0"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    )
  }
}

export default Navbar
