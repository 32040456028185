import React from 'react'
import { Row, Col } from 'reactstrap'

// Style
import style from './Footer.module.scss'

// Components
import Section from '../Section'

class Footer extends React.Component {
  shouldComponentUpdate() {
    return false
  }

  render() {
    const {
      leftColumnTitle,
      leftColumnBody,
      centerColumnTitle,
      centerColumnBody,
      rightColumnTitle,
      rightColumnBody,
    } = this.props

    return (
      <Section className={style.root}>
        <Row>
          <Col md={{ size: 4 }}>
            <h3 className="u-text--detail">{leftColumnTitle}</h3>
            <div
              className="u-text--info"
              dangerouslySetInnerHTML={{
                __html: leftColumnBody,
              }}
            />
          </Col>
          <Col md={{ size: 4 }}>
            <h3 className="u-text--detail">{centerColumnTitle}</h3>
            <div
              className="u-text--info"
              dangerouslySetInnerHTML={{
                __html: centerColumnBody,
              }}
            />
          </Col>
          <Col md={{ size: 4 }}>
            <h3 className="u-text--detail">{rightColumnTitle}</h3>
            <div
              className="u-text--info"
              dangerouslySetInnerHTML={{
                __html: rightColumnBody,
              }}
            />
          </Col>
        </Row>
      </Section>
    )
  }
}

export default Footer
