import React from 'react'
import cn from 'classnames'

import style from './Section.module.scss'

import { Container } from 'reactstrap'

const Section = ({ type, heading, children, className }) => {
  const classes = cn({
    [`t-${type}`]: type,
    [className]: className,
  })
  return (
    <section className={classes}>
      <Container className={`${style.container}`}>
        {heading && <header className={style.header}>{heading}</header>}
        <div className="position-relative">{children}</div>
      </Container>
    </section>
  )
}

export default Section
