import React from 'react'

// Styles
import '../../styles/app.scss'

// Components
import Header from '../Header'
import Footer from '../Footer'
import { HelmetProvider } from 'react-helmet-async'

const Layout = ({ children, pageContext, path }) => {
  const {
    title,
    body,
    footer_left_title,
    footer_left_body,
    footer_center_title,
    footer_center_body,
    footer_right_title,
    footer_right_body,
  } = pageContext
  return (
    <HelmetProvider>
      <Header title={title} description={body} path={path} />
      <div>{children}</div>
      <Footer
        leftColumnTitle={footer_left_title}
        leftColumnBody={footer_left_body}
        centerColumnTitle={footer_center_title}
        centerColumnBody={footer_center_body}
        rightColumnTitle={footer_right_title}
        rightColumnBody={footer_right_body}
      />
    </HelmetProvider>
  )
}

export default Layout
